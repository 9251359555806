body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Chakra Petch", sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: "Chakra Petch", sans-serif;
}

.gridfullwidth{ max-width: 100% !important; width: 100%;}

.pending{    color: #212529;
  background-color: #ffc107;
  border:1px solid #ffc107;     
  padding: 0.375rem 0.75rem; 
  border-radius: 5px; 
  font-weight: 600;
  font-size: 11px;}

.success{
  color: #fff;
  background: #28a745;
  border:1px solid #28a745;
  padding: 0.375rem 0.75rem; 
  border-radius: 5px; 
  font-weight: 600;
  font-size: 11px;
  font-family: "Chakra Petch", sans-serif;
}

.active{
  color: #F3722C !important;
  background: #313E59;
  border:1px solid #313E59;
  padding: 0.375rem 0.75rem; 
  border-radius: 0px; 
  font-weight: 600;
  font-size: 11px;
  font-family: "Chakra Petch", sans-serif;
}

.inactive{
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  padding: 0.375rem 0.75rem; 
  border-radius: 5px; 
  font-weight: 600;
  font-size: 11px;
  font-family: "Chakra Petch", sans-serif;
}

.copylink {
  display: flex;
  flex-direction: column;
}
.copylink p{
  background: #ffe2bd;
  width: 100%;
  float: left;
  padding: 15px;
  border-radius: 5px;
  color: #0c2556;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: "Chakra Petch", sans-serif;
}

.copylinkurl{
  border: 1px solid #cacaca;
  border-radius: 0px;
  height: 40px;
  padding: 4px 5px 4px 20px;
  line-height: 32px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-family: "Chakra Petch", sans-serif;
}

.copylinkurl span{
  width: calc(100% - 100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Chakra Petch", sans-serif;
}
.depositebtn{
  
  background: #2ca9d6;
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid #2ca9d6;
  border-radius: 5px;
  width: 75px;
  margin-left: 15px;;
  height: 38px;
  font-family: "Chakra Petch", sans-serif;
}
.depositebtn:hover{
  background: #2ca9d6;
  box-shadow: none;
}

.depositebtnsearch{
   
  background: #f3722c;
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid #f3722c;
  border-radius: 0px;
  /* width: 75px; */
  /* margin-left: 15px;; */
  height: 38px; box-shadow: none; font-family: "Chakra Petch", sans-serif;
}

.depositebtnsearch:hover{
  background: #f3722c;
  box-shadow: none;
}
.calenderheight{ height: 40px;}

.settlementstable{ margin: 0px; width: 100%;}

.MuiBox-root{ border-radius: 8px;}

.menulist li{ padding: 0px;}

.css-1lcvsa9:last-child{padding: 0px;}

.css-1hizqsf-MuiCardContent-root:last-child{padding: 0px;}

.menulist a{
 display: flex;
 width: 100%;
 color: #A3ADC2;
  padding: 8px 20px;
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-family: "Chakra Petch", sans-serif;
}
.menulist a:hover{ background:#313E59}

.menulist a svg path{fill:#A3ADC2;}

.menulist a.active svg path{fill:#F3722C;}

.menulist a span{ font-size: 13px; font-weight: 500; font-family: "Chakra Petch", sans-serif;     white-space: break-spaces;}

.menulist h5{font-size: 12px;
  color: #A3ADC2;
  line-height: 12px;
  margin-left: 20px;
  margin-bottom: 15px;
  font-weight: 800; font-family: "Chakra Petch", sans-serif;
}

.menulist hr{ border: 1px solid rgba(217, 217, 217, 0.6);}

.MuiDataGrid-columnHeaders{ min-height: 46px;
  max-height: 46px !important;
  line-height: 46px !important;
  min-height:46px !important;
  background:#0f172a; color: #fff;}

  .MuiDataGrid-virtualScroller{
    min-height: 56px;
  }

  .copylinkbtn{
    height: 30px;
  background: #339900;
  border-radius: 0px;
  color: #0c2556;
  padding: 0 10px;
  font-size: 12px; font-weight: 600; color: #fff; font-family: "Chakra Petch", sans-serif;
  }
  .copylinkbtn:hover{ background: #339900;}

  .upatestext{
    background: #ffe2e2;
  font-size: 13px;
  color: #e74747;
  padding: 8px 15px;
  border-radius: 5px;
  margin-bottom: 25px;
  font-weight: 600;
  text-transform: uppercase; font-family: "Chakra Petch", sans-serif;
  }
.approvaluserspopup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: rgb(255, 255, 255);
  /* border: 2px solid rgb(0, 0, 0); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding:25px 25px;
  border-radius: 0px;
}

.approvaluserspopup h4{
  margin: 0px 0px 15px 0px;
  font-size: 16px;
  color: #F3722C;
 
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700; text-transform: uppercase;
}

.approvalcommissionpopup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: rgb(255, 255, 255);
  /* border: 2px solid rgb(0, 0, 0); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding:40px 25px;
  border-radius: 0px;
}

.approvalcommissionpopup h4{
  margin: 0px 0px 15px 0px;
  font-size: 24px;
  color: #F3722C;
  
  font-family: "Chakra Petch", sans-serif;
}

.approvalview p{
 margin: 0px 0px 6px 0px;
 font-size: 14px;
 font-weight: 500; font-family: "Chakra Petch", sans-serif;
}

.closebtn{
  background: #c62828;
  font-size: 14px;
  color: #fff;
  padding: 5px 50px;
  font-weight: 600;
  margin-top: 18px;
  height: 40px; box-shadow:none; border-radius:0px; font-family: "Chakra Petch", sans-serif;
}

.nobtn{
  background: #c62828;
  font-size: 16px;
  color: #fff;
  padding: 5px 24px;
  font-weight: 600;
  margin-top: 18px;
  height: 40px; box-shadow:none; border-radius:0px; font-family: "Chakra Petch", sans-serif;
}

.savebtn{
  background: #2e7d32;
  font-size: 14px;
  color: #fff;
  padding: 5px 20px;
  font-weight: 600;
  margin-top: 18px;
  height: 40px; box-shadow:none; border-radius:0px; font-family: "Chakra Petch", sans-serif;
}

.yesbtn{
  background: #2e7d32;
  font-size: 16px;
  color: #fff;
  padding: 5px 20px;
  font-weight: 600;
  margin-top: 18px;
  height: 40px; box-shadow:none; border-radius:0px; font-family: "Chakra Petch", sans-serif;
}

.searchbtn{
  background: #2e7d32;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  /* height: 40px; width: 100px;  */
  border-radius: 0px; font-family: "Chakra Petch", sans-serif;
} 

.container {
  text-align: right; /* Aligns content to the right */
}

.withdrawbtn {
  background: #2e7d32;
  font-size: 16px;
  color: #fff;
  padding: 5px 20px;
  font-weight: 600;
  width: 150px;
  position: relative;
  bottom: 40px; font-family: "Chakra Petch", sans-serif;
}

.postsettlements {
  background: #2e7d32;
  font-size: 16px;
  color: #fff;
  padding: 5px 20px;
  font-weight: 600;
  width: 250px; font-family: "Chakra Petch", sans-serif;
}

.inputtext .Mui-error{ font-size: 12px;}
.passwordinput svg{ color:inherit !important;}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 10px 16px 16px 16px;
  padding: 0;
  width: calc(100% - 32px);
  table-layout: fixed;
  padding-bottom: 20px;
  background: #e8edf0;
  font-family: "Chakra Petch", sans-serif;

}

.MuiBox-root{
  /* background: #e8edf0; */
}

table caption {
  font-size: 14px;
  margin:0px 0px 10px 0px;
  color: #F3722C;
  font-weight: 700;
  text-align: left;
  padding-left: 0px; font-family: "Chakra Petch", sans-serif;
  
}
input{font-family: "Chakra Petch", sans-serif !important; font-weight: 400;}
caption{ font-weight: 700; font-size: 14px; text-transform: uppercase; font-family: "Chakra Petch", sans-serif;}

table tr {
  background-color: #fff;
 

}

table thead {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

table thead tr{ background: #0f172a;  border: 1px solid #5b6882 !important;
 }
table tbody tr { border-bottom: 1px solid #ccc;}


table .MuiTableRow-root:hover td:after{ background: none !important;}

table td {
  padding: 5px 10px;
  text-align: left;
  
  font-family: "Chakra Petch", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  height: 36px!important;
  border-right: 1px solid #ccc;
  line-height: 18px;
}

table td button{ background: #F3722C; padding: 8px 12px; border: 0px; color: #fff; font-weight: 700; font-size: 12px; border-radius: 0px;display:flex; align-items: center; justify-content:center; 
   
   font-family: "Chakra Petch", sans-serif;
  }

table td:first-child {  border-left: 1px solid #ccc;}

table th{   
  color: #fff;
  line-height: 16px!important;
  height: 32px!important;
  padding: 5px 10px;
  
  font-family: "Chakra Petch", sans-serif;
  font-size: 12px;
  font-weight: 700;
  border-right: 1px solid #5b6882;
}

table th small{
  font-size: 11px;
}

table th:last-child{ border-right: 1px solid #5b6882; }

table th:first-child{ border-left: 1px solid #5b6882; }
  

table th {
  /* font-size: .85em; */
  /* letter-spacing: .1em; */
  text-transform: capitalize;
  /* white-space: nowrap; */
  text-align: left;
}

.sportsdashboard .MuiPaper-elevation{ background-color: #EFF2FE !important; border-radius: 0px;  box-shadow: none; border: 1px solid #d9dbe7; }

.sportsdashboard .MuiPaper-elevation .MuiCardContent-root:last-child:last-child{ padding-bottom: 16px !important;}

.sportsdashboard .MuiPaper-elevation p:first-child{ color: #5778F5; font-weight: 500; font-size: 24px; line-height: 24px;}

.sportsdashboard .MuiPaper-elevation p{ color: #202b40; font-weight: 400; font-size: 18px;}

.egamesdashboard .MuiPaper-elevation{  background-color: #FBEEE9 !important; border-radius: 0px; color: #ED7441; box-shadow: none; border: 1px solid #eddbd4;}

.egamesdashboard .MuiPaper-elevation .MuiCardContent-root:last-child:last-child{ padding-bottom: 16px !important;}

.egamesdashboard .MuiPaper-elevation p:first-child{ color: #ED7441; font-weight: 500; font-size: 24px; line-height: 24px;}

.egamesdashboard .MuiPaper-elevation p{ color: #202b40; font-weight: 400; font-size: 18px;}

.commissionwallet .MuiPaper-elevation{ background-color: #F0EDF9 !important; border-radius: 0px;  box-shadow: none; border: 1px solid #dcd9eb;}

.commissionwallet .MuiPaper-elevation .MuiCardContent-root:last-child:last-child{ padding-bottom: 16px !important;}

.commissionwallet .MuiPaper-elevation p:first-child{ color: #6F51C3; font-weight: 500; font-size: 24px; line-height: 24px;}

.commissionwallet .MuiPaper-elevation p{ color: #202b40; font-weight: 400; font-size: 18px;}


.pendingplayers .MuiPaper-elevation{background-color:#EDF9F1 !important; border-radius: 10px;  box-shadow: none;}

.pendingplayers .MuiPaper-elevation .MuiCardContent-root:last-child:last-child{ padding-bottom: 16px !important;}


.pendingplayers .MuiPaper-elevation p:first-child{ color: #17903B; font-weight: 500; font-size: 24px; line-height: 24px;}

.pendingplayers .MuiPaper-elevation p{ color: #202b40; font-weight: 400; font-size: 18px;}


.sidebarview hr{ border-color:#313E59 !important}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: solid 1px grey;
}
#initechOrgChart .nodeLineBorderTop {
  border-top: solid 1px grey;
}
.initechOrgChart table{ width: auto !important;     border-spacing: 2px;}

.initechOrgChart .nodeLineBorderTop {
  border-top: solid 2px #000 !important;
}

.otpErrorMessage{
  color: #d32f2f;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.hierarchtable .MuiBox-root{ background: #0f172a; border-radius: 0px; color:#fff;}

.hierarchtable table .MuiTableRow-root:hover td:after{ background: #f5fff7;}

/* .hierarchtable table .MuiTableRow-root{ background: #e4eeff;} */

.hierarchtable table thead th{background: #0f172a; height: 35px;}

.hierarchtable table tbody td{ padding:0px 10px; font-weight: 400;}

@media screen and (max-width: 600px) {

  /* table tbody tr{ border: 1px solid #ddd;         border-radius: 10px;}
  table th:last-child{ border-right: 0px; }
  table td:first-child {  border-left: 0px; border-right:0px}
  table td{  border-left: 0px; border-right:0px}
  table th:first-child{ border-left: 0px; }
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 20px;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  } */
  table td {

    font-size: 10px;
    font-weight: 800;
    word-break: break-word;
    /* line-height: 11px !important; */
    padding: 6px 6px !important;
  
  }

  table td.amount{
    text-align: right;
  }

  table caption{
    font-size: .8em;
    font-weight: 800;
    line-height: 11px !important;
    padding: 6px 6px !important;
  }

  table th {
    word-break: break-word;
    padding: 6px 6px !important;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px !important;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    /* content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase; */
  }
  
  table td:last-child {
    border-bottom: 0;
  }


  
}

@media screen and (max-width: 800px) {
  table tbody tr td:before {
      content: attr(data-label);
      float: left;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
  }
  table tbody tr td:first-child {
    padding-left: 10px;
}
table thead {
  display: none;
}
table tbody tr td {
  display: block;
  text-align: right;
  font-size: 10px;
  padding: 0px 10px !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  line-height: 35px;
}
}
/* general styling */
body {
  font-family: "Chakra Petch", sans-serif !important;

  line-height: 1.25;
  font-weight: 600;
}

.MuiIconButton-root{ color:#fff;}

.MuiBreadcrumbs-ol{background: #fff; padding: 10px;}

.MuiBreadcrumbs-ol li a{ font-weight: 400; font-family: "Chakra Petch", sans-serif;}